/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// import "./src/components/layout.css"
// import "bootstrap/dist/css/bootstrap.css"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

// You can delete this file if you're not using it
const portal = document.createElement("div")
portal.id = "modal-portal"
// document.body.appendChild(portal)
// 	<Helmet>
//   <link
//     rel="stylesheet"
//     type="text/css"
//     charset="UTF-8"
//     href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
//   />{" "}
//   <link
//     rel="stylesheet"
//     type="text/css"
//     href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
//   />{" "}
// </Helmet>
